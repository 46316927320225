<template>
    <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
        <template v-slot:top>
          <v-toolbar flat color="white">
              <v-toolbar-title>{{ title }}</v-toolbar-title>

              <div class="flex-grow-1"></div>

              <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="mt-5" v-on="on">New Item</v-btn>
              </template>
              
              <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                  <v-container>
                      <v-row>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.virtualTour.title" label="Matterport Title"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.virtualTour.url" label="URL"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.walkthroughVideo.title" label="YouTube Title"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.walkthroughVideo.url" label="URL"></v-text-field>
                      </v-col>
                      <v-col cols="2" class="padding-input">
                          <v-text-field v-model="editedItem.order" label="Order"></v-text-field>
                      </v-col>
                      <v-col cols="10"></v-col>
                      </v-row>
                  </v-container>
                  </v-card-text>

                  <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="primary darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" text @click="save" :disabled="!formIsValid">Save</v-btn>
                  </v-card-actions>
              </v-card>
              </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
  name: "AppVirtualTourList",

  props: {
    title: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {
      virtualTour: {
        title: "",
        url: ""
      },
      walkthroughVideo: {
        title: "",
        url: ""
      },
      order: 0
    },
    defaultItem: {
      virtualTour: {
        title: "",
        url: ""
      },
      walkthroughVideo: {
        title: "",
        url: ""
      },
      order: 0
    },
    originalItem: {
      virtualTour: {
        title: "",
        url: ""
      },
      walkthroughVideo: {
        title: "",
        url: ""
      },
      order: 0
    },
    formFieldsChanged: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      let list = [
        { text: "Matterport Title", value: "virtualTour.title", width: 200 },
        { text: "URL", value: "virtualTour.url" },
        { text: "YouTube Title", value: "walkthroughVideo.title", width: 200 },
        { text: "URL", value: "walkthroughVideo.url" },
        { text: "Order", value: "order", width: 100 },
        { text: "", value: "action", sortable: false, width: 100 }
      ];

      return list;
    },
    formIsValid() {
      this.updateFormFieldsChanged();
      return this.formFieldsChanged;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.virtualTour = Object.assign({}, item.virtualTour);
      this.editedItem.walkthroughVideo = Object.assign(
        {},
        item.walkthroughVideo
      );
      this.originalItem = Object.assign({}, item);
      this.originalItem.virtualTour = Object.assign({}, item.virtualTour);
      this.originalItem.walkthroughVideo = Object.assign(
        {},
        item.walkthroughVideo
      );

      this.dialog = true;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("deleteVirtualTourList", {
            collection: this.collection,
            item: item
          })
          .then(
            () => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);
              this.resetItem();
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
    },
    close() {
      this.dialog = false;
      this.resetItem();
    },
    resetItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.virtualTour = Object.assign(
        {},
        this.defaultItem.virtualTour
      );
      this.editedItem.walkthroughVideo = Object.assign(
        {},
        this.defaultItem.walkthroughVideo
      );
      this.editedIndex = -1;
    },
    save() {
      if (this.editedIndex > -1) {
        const index = this.editedIndex;

        this.$store
          .dispatch("updateVirtualTourList", {
            collection: this.collection,
            item: this.editedItem
          })
          .then(
            response => {
              Object.assign(this.items[index], response.item);
              Object.assign(
                this.items[index].virtualTour,
                response.item.virtualTour
              );
              Object.assign(
                this.items[index].walkthroughVideo,
                response.item.walkthroughVideo
              );
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      } else {
        this.$store
          .dispatch("createVirtualTourList", {
            collection: this.collection,
            item: this.editedItem
          })
          .then(
            response => {
              this.items.push(response.item);
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
      this.close();
    },
    updateFormFieldsChanged() {
      this.formFieldsChanged =
        this.editedItem.virtualTour.title !==
          this.originalItem.virtualTour.title ||
        this.editedItem.virtualTour.url !== this.originalItem.virtualTour.url ||
        this.editedItem.walkthroughVideo.title !==
          this.originalItem.walkthroughVideo.title ||
        this.editedItem.walkthroughVideo.url !==
          this.originalItem.walkthroughVideo.url ||
        this.editedItem.order !== this.originalItem.order;
    }
  }
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}
</style>