<template>
  <div>
    <AppVirtualTourList title="Virtual Tour" collection="virtual-tour-list" header="Virtual Tour" :items= virtualTourList />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppVirtualTourList from "@/components/admin/AppVirtualTourList";

export default {
  name: "VirtualTour",

  components: {
    AppVirtualTourList
  },

  computed: {
    ...mapGetters(["virtualTourList"])
  }
};
</script>
